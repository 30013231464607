import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import { FormInput, formInputProps } from '../FormInput';
import Eye from '../../../../images/svg/eye.svg';
import EyeSlash from '../../../../images/svg/eye-slash.svg';
import styles from './FormPasswordInput.css';

const cx = classNames.bind(styles);

export const FormPasswordInput = ({
  errors,
  name,
  touched,
  showPasswordTest,
  className,
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const hasError = errors[name] && touched[name];

  const containerCss = cx(
    'flex bg-white border border-solid border-grey-2 rounded-full',
    { 'border-red text-red': hasError }
  );
  const inputCss = cx('py-4 pl-8 pl-2 rounded-l-full', className);
  const buttonCss = cx(
    'flex items-center pr-8 focus:outline-none',
    'togglePassword',
    {
      error: hasError,
    }
  );

  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <div className={containerCss} data-testid="form-group-field-container">
      <FormInput
        errors={errors}
        touched={touched}
        name={name}
        className={inputCss}
        type={showPassword ? 'text' : 'password'}
        unstyled
        {...rest}
      />
      <button
        className={buttonCss}
        onClick={toggleShowPassword}
        type="button"
        data-testid={showPasswordTest}
        tabIndex="-1"
      >
        {showPassword ? <EyeSlash /> : <Eye />}
      </button>
    </div>
  );
};

FormPasswordInput.propTypes = {
  ...formInputProps,
  showPasswordTest: PropTypes.string,
};
